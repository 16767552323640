import React from 'react';
import './App.scss';

function App() {
  return (
    <div className="App">
      <div className="page">
        <header className="App-header">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <img src={process.env.PUBLIC_URL + 'sumit-logo.jpg'} className="App-logo" alt="logo" />
        </header>
        <section className="description">
          <section className="sub-head">
            <h1 className="name">Sumit Kushwaha</h1>
            <h2 className="title">Software Engineer based in Bangalore, India</h2>
          </section>
          <section className="about">
            <p><strong>Hey there!</strong> I am a Software Engineer working in Directi. </p>
            <p>My work is mostly focused on frontend development of complex mail and messaging apps. I am a polyglot engineering generalist who believes in core software engineering principles and I use optimal tools to create optimal solutions.</p>
            <p>When I don't code, I like to learn things around technology, religion, economics, finance and politics.</p>
          </section>
        </section>
      </div>
      <section className="sm-icon-sec">
          <a href="https://in.linkedin.com/in/sumit6b" target="_blank">
            <img src={process.env.PUBLIC_URL + '/sm/linkedin.svg'} className="sm-icon" alt="linkedin"/>
          </a>
          <a href="https://www.facebook.com/sumit6b" target="_blank">
            <img src={process.env.PUBLIC_URL + '/sm/facebook.svg'} className="sm-icon" alt="facebook"/>
          </a>
          <a href="https://www.instagram.com/sumit6b/" target="_blank">
            <img src={process.env.PUBLIC_URL + '/sm/instagram.svg'} className="sm-icon" alt="instagram"/>
          </a>
          <a href="https://twitter.com/sumit6b" target="_blank">
            <img src={process.env.PUBLIC_URL + '/sm/twitter.svg'} className="sm-icon" alt="twitter"/>
          </a>
          
        </section>
    </div>
  );
}

export default App;
